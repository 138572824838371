global.toastr = require("toastr")
import { CountUp } from 'countup.js'

toastr.options = {
  "closeButton": false,
  "debug": false,
  "progressBar": false,
  "positionClass": "toast-bottom-left",
  "showDuration": "100000",
  "hideDuration": "1000",
  "timeOut": "80000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

const submitLearnMoreForm = (event) => {
  event.preventDefault();

  const [nameInput, phoneInput] = event.currentTarget;
  const name = nameInput.value;
  const phone = phoneInput.value;

  $.post( "/landing/lead_learn_more", { lead: {name: name, phone: phone} }, function( data ) {
    toastr.success('<svg width="1em" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="text-align: center; display: inline-block;"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"></path></svg> <span class="typography font-inter">Заявка принята. Спасибо!</span>');
    nameInput.value = '';
    phoneInput.value = '';
  });
};

const submitRequestForm = (event) => {
  event.preventDefault();

  const [firstNameInput, lastNameInput, emailInput, companyInput, issueInput] = event.currentTarget;
  const firstName = firstNameInput.value;
  const lastName = lastNameInput.value;
  const email = emailInput.value;
  const company = companyInput.value;
  const issue = issueInput.value;

  $.post( "/landing/lead_request", { lead: {first_name: firstName, last_name: lastName, email: email, company: company, issue: issue } }, function( data ) {
    toastr.success('<svg width="1em" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="text-align: center; display: inline-block;"><path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z"></path></svg> <span class="typography font-inter">Заявка принята. Спасибо!</span>');
    firstNameInput.value = ''
    lastNameInput.value = ''
    emailInput.value = ''
    companyInput.value = ''
    issueInput.value = ''
  });
};


function setReliabilityAnimation(){
  const intersectionCallback = function(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const parent = document.querySelector('#reliability-items')
        if (parent) {
          for (let i = 0; i < parent.children.length; i++) {
            parent.children.item(i)?.classList.add('animate-appear')
          }
        }
      }
    });
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  let observer = new IntersectionObserver(intersectionCallback, options);
  observer.observe(document.querySelector('#reliability-items'));
}

function setAccuracyAnimation(){
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  const intersectionCallback = function(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        new CountUp('counter1', 98.6, {suffix: '%', decimal: '.', decimalPlaces: 1}).start();
        new CountUp('counter2', 91, {suffix: '%'}).start();
        new CountUp('counter3', 99.77, {suffix: '%', decimal: '.', decimalPlaces: 2}).start();
      }
    });
  };


  let observer = new IntersectionObserver(intersectionCallback, options)
  observer.observe(document.querySelector('#accuracy-section'));
}

function tooltip(element) {
  let div;
  let title;

  function mouseOver(event) {
    // NOTE: remove the `title` attribute, to prevent showing the default browser tooltip
    // remember to set it back on `mouseleave`
    title = element.getAttribute('title');
    element.removeAttribute('title');

    div = document.createElement('div');

    const innerWidth = event.view?.innerWidth ?? 0
    const x = event.pageX
    const tooltipWidth = 240
    let tooltipLeft = x + 5

    if (tooltipLeft + tooltipWidth > innerWidth) {
      tooltipLeft = innerWidth - tooltipWidth - 45
    }

    div.innerHTML = title;
    div.style = `
            box-sizing: border-box;
            max-width: 240px;
			border: 1px solid transparent;
			box-shadow: 0 0 10px rgba(159, 25, 171, 0.8);
			background: linear-gradient(94deg, #5E1864 -36.91%, #281033 -13.72%, #0E0926 99.01%);
			border-radius: 12px;
			padding: 16px 12px;
			position: absolute;
            font-family: 'Inter', sans-serif;
            color: rgba(255, 255, 255, 0.8);
			top: ${event.pageY + 15}px;
			left: ${tooltipLeft}px;
		`;
    document.body.appendChild(div);
  }
  function mouseMove(event) {
    const innerWidth = event.view?.innerWidth ?? 0
    const x = event.pageX
    const tooltipWidth = 240
    let tooltipLeft = x + 5

    if (tooltipLeft + tooltipWidth > innerWidth) {
      tooltipLeft = innerWidth - tooltipWidth - 45
    }

    div.style.left = `${tooltipLeft}px`;
    div.style.top = `${event.pageY + 15}px`;
  }
  function mouseLeave() {
    document.body.removeChild(div);
    // NOTE: restore the `title` attribute
    element.setAttribute('title', title);
  }

  element.addEventListener('mouseover', mouseOver);
  element.addEventListener('mouseleave', mouseLeave);
  element.addEventListener('mousemove', mouseMove);
}


$( document ).ready(function() {
  setReliabilityAnimation();
  setAccuracyAnimation();

  let elements = [...document.getElementsByClassName('tooltip')];
  elements.forEach( function(element) {
    tooltip(element);
  });

  $('.learn-form').submit(submitLearnMoreForm);

  $('.request-form').submit(submitRequestForm);

  $('.rules-item').click(function(){
    window.location="#learn-form"
  })
})